<template>
<div class="flex flex-wrap flex-align-end flex-justify-center content-wrap top-gap-margin">
<div>
 <div class="title left-gap">
     <title-text-block v-bind:data="{title, text, align: titleAlign}"></title-text-block>
 </div>
   <!-- <img class="tech-img top-gap-margin" src="~@/assets/img/technologies/technologies.svg"> -->
   <img class="tech-img top-gap-margin" src="~@/assets/img/technologies/technologies_mobile.svg">
</div>
<div>
   <img class="scheme-img top-gap-margin" src="~@/assets/img/technologies/scheme.svg">
    <img class="scheme-img_mobile" src="~@/assets/img/technologies/scheme_mobile.svg">
</div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import  TitleTextBlock  from "@/components/ui/TitleTextBlock.vue"; 


@Options({
  components: {
      TitleTextBlock
  }
})
export default class TechnologiesAndApproaches extends Vue {
  title = 'Технологии и подходы';
  text = `Мы стремимся использовать надёждные современные технологии. Благодаря опыту R&D мы можем помочь сформулировать вашу проблему и подобрать оптимальное техническое решение`;
  titleAlign = 'left';
}
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  max-width: 490px;
}

.scheme-img{
  margin-left: -45px;
  width: 100%;
}

.scheme-img_mobile{
  display: none;
  width: 100%;
}

.tech-img{
  width: 100%;
  max-width: 481px;
}
@media screen and (max-width: 730px) {

.tech-img{
  margin-bottom: 40px;
}

.scheme-img_mobile{
  display: block;
}

.scheme-img{
    display: none;
}
}
</style>