
import { Options, Vue } from "vue-class-component";
import TeamMember from "../ui/TeamMember.vue";
import TeamMemberData from "@/components/ui/TeamMember.vue";

@Options({
  components: {
    TeamMember,
  },
  props: {
    items: { type: Object as () => TeamMemberData[] },
  },
})
export default class Team extends Vue {}
