
import { Options, Vue } from "vue-class-component";

 class TextTitleData{
      text!: string;
      title!: string;
      align!: string;
      isWhite!: boolean;
 }

@Options({
  props: {
    data: {type:  Object as () => TextTitleData}
  }
})
export default class TitleTextBlock extends Vue {
}
