<template>
  <div
    class="flex flex-justfy-start flex-align-center flex-wrap career top-gap-margin"
  >
    <div class="img-wrap">
      <img class="img" src="~@/assets/img/career.png" alt="" />
      <div class="bag-img" @click="sendEmail()">
        <img src="~@/assets/icons/bag.svg" alt="" />
      </div>
    </div>
    <div class="description">
      <div class="description_title h2-text">UpMetric</div>
      <div class="description_subtitle h3-text">Постройте свою карьеру</div>
      <div class="description_text">
        Если вы хотите начать в сфере машинного обучения и анализа данных,
        высылайте свое резюме на адрес <span class="email">{{email}}</span>, и мы
        свяжемся с вами. Мы готовы предложить вам гибкий график, достойную оплату труда и
        возможности для профессионального и карьерного роста.
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Career extends Vue {
  email = 'inbox@upmetric.ai';
  sendEmail(){
    window.location.href = `mailto:${this.email}`;
  }
}
</script>

<style scoped lang="scss">
.description {
  margin-left: 56px;
  max-width: 815px;
  .description_title {
    margin-bottom: 10px;
    color: $blue;
  }

  .description_subtitle {
    margin-bottom: 24px;
  }
}

.img {
  max-width: 268px;
  width: 100%;
  border-radius: 50%;
}

.bag-img {
  position: absolute;
  background-color: $blue;
  padding: 23px;
  border-radius: 50%;
  cursor: pointer;
  top: -25px;
  right: -7px;
}

.bag-img:hover {
  background-color: $dark-blue;
}
.career {
  margin: 20px;
}

.img-wrap {
  position: relative;
  margin-bottom: 20px;
}

.email{
  color: $blue;
}

@media screen and (max-width: 730px) {
 .img-wrap{
   margin-top: 30px;
 }

  .bag-img {
    padding: 17px;
    top: -3px;
    right: 10px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .description{
    margin-left: 0;
  }
}
</style>