<template>
  <button
    :disabled="disabled"
    v-bind:class="type"
    class="h4-text"
    @click="$emit('clicked', $event)"
    v-bind:type="submit?'submit': null"
  >
    {{ text }}
  </button>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: String,
    type: String,
    disabled: Boolean,
    submit: Boolean
  },
})
export default class UiButton extends Vue {
  text!: string;
  type = "light";
  disabled = false;
  submit = false;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  padding: 14px 64px;
  border-radius: 3px;
  border: none;

  &:focus {
    outline: none;
  }

  &.light {
    background-color: $white;
    color: $blue;

    &:not([disabled]) {
      &:hover {
        color: $light-blue;
      }
    }

    &:visited {
      color: $dark-blue;
    }
  }

  &.dark {
    background-color: $blue;
    color: $white;

    &:not([disabled]) {
      &:hover {
        background-color: $dark-blue;
      }
    }

    &:visited {
      background-color: $light-blue;
    }
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

@media screen and (max-width: 270px) {
  button{
    padding: 14px;
  }

}
</style>