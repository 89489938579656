<template>
  <div class="item" v-for="item in items" :key="item.alias">
        <case v-bind:data="item"  v-on:details-clicked="$emit('case-details-clicked', item)"></case> 
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { CaseData } from "./Case.vue";
import  Case from "./Case.vue";


@Options({
  components: {
    Case
  },
    props: {
    items: { type: Object as () => CaseData[] },
  }
})
export default class CasesList extends Vue {
  
}
</script>

<style scoped lang="scss">
.item{
  min-width: 0; 
  display: flex;
  width: 490px;
}
</style>