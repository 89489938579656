<template>
  <div class="case-card card flex flex-justify-space-between flex-dir-col two-column-flex_col">
    <div class="case-info white-text">
      <div class="title h3-text">{{ data.headerTitle }}</div>
      <div class="title-line"></div>
      <div class="description">{{ data.description }}</div>
      <img
        class="case-bg"
        src="~@/assets/img/backgrounds/case-result-background.svg"
      />
    </div>
    <div class="flex flex-justify-space-between details-wrap">
      <img
        class="company-img"
        :src="require(`@/assets/img/companies/${data.alias}.svg`)"
        alt=""
      />
      <div class="details flex flex-align-center flex-justify-start"  @click="$emit('details-clicked', data)">
        <span class="secondaty-text">Подробнее</span>
        <img
        src="~@/assets/icons/arrow.svg"
        alt=""
      />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
document.title = "UpMetric JSC";

import { Options, Vue } from "vue-class-component";

export class CaseData {
  id!:number;
  headerTitle!: string;
  alias!: string;
  description!: string;
}

@Options({
  props: {
    data: { type: Object as () => CaseData },
  },
})
export default class Case extends Vue {}
</script>

<style scoped lang="scss">
.case-card {
  padding: 0px 20px 20px 0px;
  width: 100%;
  min-height: 276px;
  max-width: 430px !important;
  box-shadow: 0px 25px 35px rgba(13, 76, 216, 0.1);
  margin-bottom: 40px;

  &:hover{
    box-shadow: 0 0 30px rgba(0,0,0,.15);
  }
}

.case-info{
  position: relative;
  overflow: hidden;
  background-color: $blue;
  border-radius: 5px;
  max-width: 430px;
  min-height: 195px;
  width: 100%;
}

.details {
  color: $light-blue;
  cursor: pointer;
}

.details-wrap{
    margin-top: 16px;
    margin-left: 20px;
}

.case-bg {
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 57%;
}

.title, .description{
  padding: 16px;
  position: relative;
  z-index: 300;
}

.title-line{
  border-bottom: 2px solid $white;
  max-width: 173px;
}

</style>
