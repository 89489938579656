const cases = [
    {
        id: 1,
        headerTitle: "Media-Analytics",
        description: "Система медиа мониторинга новостных ресурсов и социальных сетей, тематического моделирования, выстраивания рейтингов сущностей",
        alias: "mon",
        logoWidth: 30,
        customer: "Министерство образования и науки Республики Казахстан",

        problemTitle: "Постановка проблемы",
        problemText: `Система медиа мониторинга новостных ресурсов и социальных сетей, тематического моделирования, выстраивания рейтингов сущностей`,

        workflowItems: [
            {
                title: "Формирование требований",
                text: "Сбор требований заказчика в целях постановки задачи",
                number: 1,
            },
            {
                title: "Разработка плана работы",
                text: "Составление детального плана работ и итераций",
                number: 2,
            },
            {
                title: "Внедрение",
                text: "Развёртка на мощностях заказчика, внедрение в бизнес- и информационные процессы",
                number: 4,
            },
            {
                title: "Реализация",
                text: "Программная реализация в виде распределённой информационной системы",
                number: 3,
                border: "right",
            },
            {
                title: "Тестирование",
                text: "Мониторинг работы системы, сбор feedback от пользователей",
                number: 5,
                border: "left",
            },
            {
                title: "Сопровождение",
                text: "Техническое поддержка, сопровождение и обучение",
                number: 6,
            },
        ],


        resultsTitle: "Результаты",
        resultsText:
            "Благодаря системе медиа мониторинга новостных ресурсов и социальных сетей, тематического моделирования были достигнуты следующие показатели",

        team: [
            {
                name: "Кирилл Якунин",
                description: `CEO / сo-founder
                    10 лет опыта в области DS/ML
                    Опыт Enterprise разработки и научных исследований (PhD)`,
                alias: "kirill",
            },
            {
                name: "Санжар Мурзахметов",
                description: `CTO / co-founder
                    Опыт разработки NLP сервисов и научных исследований`,
                alias: "sanzhar",
            },
            {
                name: "Абай Бегбаганбетов",
                description: `Teamlead/Software Engineer
                    Backend разработчик с опытом работы в NLP, CV
                    Опыт Enterprise разработки и научных исследований`,
                alias: "abay",
            },
            {
                name: "Бексултан Сагындык",
                description: `Data Scientist
                    Опыт внедрения ML решенией для бизнеса по части мобильной рекламы и геоаналитики`,
                alias: "beksultan",
            },
        ],

        results: [
            {
                number: 1,
                text: "Инструмент высокоуровневой разметки корпусов для арбитрарных критериев",
            },
            {
                number: 2,
                text: "Автоматиизация процесса мониторинга СМИ и соц. сетей",
            },
            {
                number: 3,
                text: "Аналитический инструмент для визуализации собранных данных",
            },
        ]
    },
    {
        id: 2,
        headerTitle: "RecNum",
        description: "Сервис контекстных рекомендаций для крупнейшего Казахстанского ритейлера, Magnum Cash&Carry",
        alias: "magnum",
        logoWidth: 80,
        customer: "Магнум",

        problemTitle: "Постановка проблемы",
        problemText: `Сервис контекстных рекомендаций для получения списка комплементарных товаров и товаров заменителей, с целью решения задач Upsell и NPTB`,

        workflowItems: [
            {
                title: "Формирование требований",
                text: "Сбор требований заказчика в целях постановки задачи",
                number: 1,
            },
            {
                title: "Разработка плана работы",
                text: "Составление детального плана работ и итераций",
                number: 2,
            },
            {
                title: "Внедрение",
                text: "Развёртка на мощностях заказчика, внедрение в бизнес- и информационные процессы",
                number: 4,
            },
            {
                title: "Реализация",
                text: "Программная реализация в виде распределённой информационной системы",
                number: 3,
                border: "right",
            },
            {
                title: "Тестирование",
                text: "Мониторинг работы системы, сбор feedback от пользователей",
                number: 5,
                border: "left",
            },
            {
                title: "Сопровождение",
                text: "Техническое поддержка, сопровождение и обучение",
                number: 6,
            },
        ],

        resultsTitle: "Результаты",
        resultsText:
            "Благодаря системе контекстных рекомендаций были достигнуты следующие показатели",

        team: [
            {
                name: "Кирилл Якунин",
                description: `CEO / сo-founder
                    10 лет опыта в области DS/ML
                    Опыт Enterprise разработки и научных исследований (PhD)`,
                alias: "kirill",
            },
            {
                name: "Санжар Мурзахметов",
                description: `CTO / co-founder
                    Опыт разработки NLP сервисов и научных исследований`,
                alias: "sanzhar",
            },
        ],

        results: [
            {
                number: 1,
                text: "Разработан масштабируемый API для доступа",
            },
            {
                number: 2,
                text: "Разработаны рекомендации по использованию полученых моделей в реальных условиях для максимизации прибыли",
            },
            {
                number: 3,
                text: "Достигнуты высокие показатели качества рекомендаций",
            },
        ]


    },
    {
        id: 3,
        headerTitle: "B2B churn model",
        description: "Инструмент предсказания оттока B2B клиентов",
        alias: "telecom",
        logoWidth: 80,
        customer: "Казахтелеком",

        problemTitle: "Постановка проблемы",
        problemText: `Инструмент для предсказания и предотвращения оттока B2B клиентов ШПД`,

        workflowItems: [
            {
                title: "Формирование требований",
                text: "Сбор требований заказчика в целях постановки задачи",
                number: 1,
            },
            {
                title: "Разработка плана работы",
                text: "Составление детального плана работ и итераций",
                number: 2,
            },
            {
                title: "Внедрение",
                text: "Развёртка на мощностях заказчика, внедрение в бизнес- и информационные процессы",
                number: 4,
            },
            {
                title: "Реализация",
                text: "Программная реализация в виде распределённой информационной системы",
                number: 3,
                border: "right",
            },
            {
                title: "Тестирование",
                text: "Мониторинг работы системы, сбор feedback от пользователей",
                number: 5,
                border: "left",
            },
            {
                title: "Сопровождение",
                text: "Техническое поддержка, сопровождение и обучение",
                number: 6,
            },
        ],

        resultsTitle: "Результаты",
        resultsText: "Благодаря разработанному инструменту были получены следующие результаты",

        team: [
            {
                name: "Кирилл Якунин",
                description: `CEO / сo-founder
                    10 лет опыта в области DS/ML
                    Опыт Enterprise разработки и научных исследований (PhD)`,
                alias: "kirill",
            },
            {
                name: "Санжар Мурзахметов",
                description: `CTO / co-founder
                    Опыт разработки NLP сервисов и научных исследований`,
                alias: "sanzhar",
            },
        ],

        results: [
            {
                number: 1,
                text: "Более чем в 10 раз улучшена существующая методика предсказания оттока",
            },
            {
                number: 2,
                text: "Разработаны рекомендации по использованию получнных моделей",
            },
            {
                number: 3,
                text: "Произведено внедрение полученного инструмента в бизнес-процесс заказчика",
            },
        ]
    },
    {
        id: 4,
        headerTitle: `Информационная система “Инструменты семантического анализа”`,
        description: "Сервис оценки инфляционных и девальвационных ожиданий на основе текстовых документов",
        fontSize: "3rem",
        alias: "nb",
        logoWidth: 30,
        customer: "ДДКП Национального Банка Казахстана",

        problemTitle: "Постановка проблемы",
        problemText: `Система медиа мониторинга СМИ и соц. сетей с целью оценки инфляционных ожиданий населения`,

        workflowItems: [
            {
                title: "Формирование требований",
                text: "Сбор требований заказчика в целях постановки задачи",
                number: 1,
            },
            {
                title: "Разработка плана работы",
                text: "Составление детального плана работ и итераций",
                number: 2,
            },
            {
                title: "Внедрение",
                text: "Развёртка на мощностях заказчика, внедрение в бизнес- и информационные процессы",
                number: 4,
            },
            {
                title: "Реализация",
                text: "Программная реализация в виде распределённой информационной системы",
                number: 3,
                border: "right",
            },
            {
                title: "Тестирование",
                text: "Мониторинг работы системы, сбор feedback от пользователей",
                number: 5,
                border: "left",
            },
            {
                title: "Сопровождение",
                text: "Техническое поддержка, сопровождение и обучение",
                number: 6,
            },
        ],

        resultsTitle: "Результаты",
        resultsText: "Благодаря системе медиа мониторинга оценки инфляционных ожиданий были достигнуты следующие результаты",

        team: [
            {
                name: "Кирилл Якунин",
                description: `CEO / сo-founder
                  10 лет опыта в области DS/ML
                  Опыт Enterprise разработки и научных исследований (PhD)`,
                alias: "kirill",
            },
            {
                name: "Санжар Мурзахметов",
                description: `CTO / co-founder
                  Опыт разработки NLP сервисов и научных исследований`,
                alias: "sanzhar",
            },
            {
                name: "Эльмира Баймуратова",
                description: `Senior Front-end разработчик и дизайнер с опытом UI/UX`,
                alias: "elmira",
            },
        ],

        results: [
            {
                number: 1,
                text: "Автоматизирован процесс мониторинга СМИ и соц. сетей",
            },
            {
                number: 2,
                text: "Достигнуты высокие показатели точности предсказания инфляционных ожиданий (корреляция 0.6-0.8)",
            },
            {
                number: 3,
                text: "Внедрён аналитический инструмент для анализа ситуации в области инфляционных ожиданий и формирование отчётов",
            },
        ]
    },
]

export default cases;
