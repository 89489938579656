
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class SpecItem {
  Icon!: string;
  Description!: string;
}

class DataItem {
  Title!: string;
  SpecItems!: SpecItem[];
  Active?: boolean;
}

@Options({
  components: {
    TitleTextBlock,
  },
})
export default class Specialization extends Vue {
  title = "На чем мы специализируемся?";
  text = `Разработка и внедрение machine learning / deep learning driven решений.
          Реализация полного цикла разработки задач, связанных с данными: от сбора до внедрения в бизнес-процессы и сопровождения`;
  titleAlign = "center";

  items: DataItem[] = [
    {
        Active: true,
        Title: "Рекомендательные движки",
        SpecItems: [
          {
            Icon: "commerce-and-shopping",
            Description: "Персонализированные рекомендации"
          },
          {
            Icon: "message",
            Description: "Холодный старт - рекомендации без истории покупок"
          },
          {
            Icon: "profit",
            Description: "Оптимизация конверсии и маржинальности"
          }
        ],
      },
      {
        Title: "Медиа мониторинг",
        SpecItems: [
          {
            Icon: "social",
            Description: "Мониторинг масс медиа и социальных сетей"
          },
          {
            Icon: "star",
            Description: "Рейтинг освещённости организаций, лиц, мероприятий"
          },
          {
            Icon: "bar-chart",
            Description: "Сопоставление с маркетинговыми показателями"
          },
        ],
      },
      {
        Title: "Распознавание объектов",
        SpecItems: [
          { Icon: "camera", Description: "Распознавание объектов с камер" },
          {
            Icon: "security",
            Description:
              "Обеспечение безопасности, соблюдения мер предосторожности"
          },
          { Icon: "face", Description: "Распознавание лиц" }
        ],
      },
       {
        Title: "Чат-боты",
        SpecItems: [
          {
            Icon: "comment",
            Description: "Диалоговые системы для сервис центров"
          },
          {
            Icon: "automation",
            Description: "Автоматизация бизнес-процессов"
          }
        ],
      },
      {
        Title: "Задачи анализа клиентской базы",
        SpecItems: [
          { Icon: "enter", Description: "Предсказание оттока" },
          {
            Icon: "customer",
            Description:
              "Сегментация клиентов",
          },
          { Icon: "chart", Description: "Up-sell, next product to buy" },
          { Icon: "location", Description: "Гео-аналитика, оптимизация расположения точек продаж" }
        ],
      },
      {
        Title: "Монетизация данных, R&D",
        SpecItems: [
          { Icon: "science", Description: "Опыт научных исследований на международном уровне" },
          {
            Icon: "support",
            Description:
              "Консалтинг, помощь в постановке задач, связанных с данными"
          },
          { Icon: "magnifying-glass", Description: "R&D, проверка гипотез, пилоты, прототипы, A/B тестирование" }
        ],
      }
    ];

  activeSpecItems: SpecItem[] = this.items[0].SpecItems;;

  setActiveSpec(index: number) {
    this.activeSpecItems = this.items[index].SpecItems;
    this.items.forEach((i, indx) => {
      if (index === indx) {
        i.Active = true;
      } else {
        i.Active = false;
      }
    });
  }
}
