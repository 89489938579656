<template>
  <div class="main-wrap">
    <main-header></main-header>
    <div class="gradient-block full-width">
      <div class="content">
        <div id="specialization">
          <specialization></specialization>
        </div>
        <div id="technologies">
          <technologies-and-approaches></technologies-and-approaches>
        </div>
      </div>
    </div>
    <div class="team-block full-width" id="team">
      <div class="content">
        <div
          class="h3-text white-text team-block_title text-center top-gap-margin"
        >
          Наша команда
        </div>
        <team v-bind:items="team"></team>
        <img
          class="team-bg"
          src="~@/assets/img/backgrounds/team-background-lights.svg"
        />
      </div>
    </div>
    <div class="white-block top-gap-padding" id="companies">
      <div class="content">
        <companies></companies>
      </div>
    </div>
    <div class="gray-block top-gap-padding" id="cases">
      <div class="content">
        <cases v-bind:items="casesList"></cases>
      </div>
    </div>
    <div class="white-block top-gap-padding" id="order">
      <div class="content"> 
          <order></order>
        <div id="career">
          <career></career>
        </div>
      </div>
    </div>
    <div class="content">
      <ui-footer></ui-footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MainHeader from "@/components/main-page/MainHeader.vue";
import Specialization from "@/components/main-page/Specialization.vue";
import TechnologiesAndApproaches from "@/components/main-page/technologies-and-approaches/TechnologiesAndApproaches.vue";
import Team from "@/components/ui/Team.vue";
import Companies from "@/components/main-page/companies/Companies.vue";
import Cases from "@/components/main-page/cases/Cases.vue";
import Order from "@/components/main-page/Order.vue";
import Career from "@/components/main-page/Career.vue";
import UiFooter from "@/components/main-page/Footer.vue";

import  cases  from "@/assets/data/cases.js";

@Options({
  components: {
    MainHeader,
    Specialization,
    TechnologiesAndApproaches,
    Team,
    Companies,
    Cases,
    Order,
    Career,
    UiFooter,
  },
})
export default class Main extends Vue {
  team = [
    {
      name: "Кирилл Якунин",
      description: `CEO / сo-founder
              10 лет опыта в области DS/ML
              Опыт Enterprise разработки и научных исследований (PhD)`,
      alias: "kirill",
    },
    {
      name: "Санжар Мурзахметов",
      description: `CTO / co-founder
              Опыт разработки NLP сервисов и научных исследований`,
      alias: "sanzhar",
    },
    {
      name: "Абай Бегбаганбетов",
      description: `Teamlead/Software Engineer
              Backend разработчик с опытом работы в NLP, CV
              Опыт Enterprise разработки и научных исследований`,
      alias: "abay",
    },
    {
      name: "Берген Асым",
      description: `Software Engineer 
                    Backend разработчик с опытом работы в ML/DL`,
      alias: "bergen",
    },
    {
      name: "Бексултан Сагындык",
      description: `Data Scientist
                    Опыт внедрения ML решенией для бизнеса по части мобильной рекламы и геоаналитики`,
      alias: "beksultan",
    },
    {
      name: "Эльмира Баймуратова",
      description: `Senior Front-end разработчик и дизайнер с опытом UI/UX`,
      alias: "elmira",
    },
  ];

  casesList = cases;
  mounted() {
    const view = this.$router.currentRoute.value.params.view;

    if (view) {
      setTimeout(() => {
        this.goToView(view);
      }, 100);
    }
  }

   goToView(view) {
    const yOffset = -60;
    const element = document.getElementById(view);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
}
</script>

<style scoped lang="scss">
.main-wrap {
  background: linear-gradient(75.87deg, #060160 9.95%, #0139ba 97%);
  width: 100%;
}

.gradient-block {
  background: linear-gradient(180deg, #ffffff 0%, #e9eeff 100%);
  margin-top: -3px;
  z-index: 350;
  position: relative;
}

.team-block_title {
  margin-bottom: 40px;
}

.team-bg {
  position: absolute;
  z-index: 200;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team-block {
  color: $white;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 68px;
}
</style>
