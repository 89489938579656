
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import CaseData  from "./Case.vue";
import  CasesList from "./CasesList.vue";


@Options({
  components: {
    TitleTextBlock,
    CasesList
  },
    props: {
    items: { type: Object as () => CaseData[] },
  }
})
export default class Cases extends Vue {
   title = "Бизнес кейсы";
   text = `Члены нашей команды завершили ряд успешных проектов`;
   titleAlign = "left";

   details(event){
    window.location.href = '/case-detail/' +  event.id; // use window.location insted of router because of problems with load json data
   }
}
