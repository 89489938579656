
import { Options, Vue } from "vue-class-component";

 export class TextMemberData{
      name!: string;
      alias!: string;
      description!: string;
 }

@Options({
  props: {
    data: {type:  Object as () => TextMemberData}
  }
})
export default class TeamMember extends Vue {
  
}
