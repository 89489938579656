
import { Options, Vue } from "vue-class-component";
import { CaseData } from "./Case.vue";
import  Case from "./Case.vue";


@Options({
  components: {
    Case
  },
    props: {
    items: { type: Object as () => CaseData[] },
  }
})
export default class CasesList extends Vue {
  
}
