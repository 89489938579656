
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Career extends Vue {
  email = 'inbox@upmetric.ai';
  sendEmail(){
    window.location.href = `mailto:${this.email}`;
  }
}
