import { createApp, h } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Inputmask from 'inputmask';

const Settings = {
  getInputMaskOptions : function(binding) {
    return binding.value;
  },
 
  getTargetElement : function(sourceEl) {
    if (sourceEl.tagName === 'INPUT') {
        return sourceEl;
    }
 
    if (sourceEl.dataset['inputmaskTarget']) {
        return sourceEl.querySelector(sourceEl.dataset['inputmaskTarget']);
    }
 
    return sourceEl.querySelector('input:not([readonly])');
  }
};

const app = createApp(App);
 

  app.directive("inputmask", {
    mounted(el, binding, vnode) {
      const inputEl = Settings.getTargetElement(el);
      const maskOptions = Settings.getInputMaskOptions(binding);

      if (maskOptions) Inputmask(maskOptions).mask(inputEl);
    },

    unmounted (el, binding, vnode) {
      const inputEl = Settings.getTargetElement(el);
     
      if (inputEl && inputEl.inputmask) {
        inputEl.inputmask.remove();
      }
    },

    updated(el, binding){
      const inputEl = Settings.getTargetElement(el);
      const maskOptions = Settings.getInputMaskOptions(binding);

      if (binding.value !== binding.oldValue) {
          if (inputEl.inputmask) {
            inputEl.inputmask.remove();
          }

          Inputmask(maskOptions).mask(inputEl);
      }
    }
  });

  app.use(router)
  .mount("#app");
  
