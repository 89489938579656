
import { Options, Vue } from "vue-class-component";
import Company from "./Company.vue";

@Options({
  components: {
    Company,
  },
  props: {
    companies:{type: Array},
  },
})
export default class CompaniesList extends Vue {}
