<template>
    <div class="two-column">
      <div class="item" v-for="item in items" :key="item.alias">
        <team-member v-bind:data="item"></team-member>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TeamMember from "../ui/TeamMember.vue";
import TeamMemberData from "@/components/ui/TeamMember.vue";

@Options({
  components: {
    TeamMember,
  },
  props: {
    items: { type: Object as () => TeamMemberData[] },
  },
})
export default class Team extends Vue {}
</script>

<style scoped lang="scss">
</style>