<template>
<div class="two-column">
  <div class="right-text-block__text">
       <title-text-block
          v-bind:data="{ title, text, align: titleAlign }"
    ></title-text-block>
  </div>
  <div class="right-text-block__content">
    <companies-list v-bind:companies="companies"></companies-list>
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CompaniesList from "./CompaniesList.vue";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";


@Options({
  components: {
    CompaniesList,
    TitleTextBlock
  }
})
export default class Companies extends Vue {
  title = "Члены нашей команды имеют опыт работы c крупными компаниями";
  text = `Клиентами выступали как частные, так и государственные предприятия`;
  titleAlign = "left";
  companies = ['telecom', 'magnum','kazatomprom','mon', 'nb', 'almaty', 'rbk', 'beeline', 'kmf'];
}
document.title = "UpMetric JSC";
</script>

<style scoped  lang="scss">
</style>
