<template>
 <div class="img-wrap flex flex-align-center flex-justify-center">
      <img class="company-img" :src="require(`@/assets/img/companies/${company}.svg`)" alt="">
 </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";


@Options({
  components: {
  },
  props:{
    company: String
  }
})
export default class Company extends Vue {
  company!: string;
}
document.title = "UpMetric JSC";
</script>

<style scoped lang="scss">
.img-wrap{
  background-color: #FAFBFF;
  border-radius: 3px;
  width: 168px;
  height: 110px;
}
</style>
