
import { Options, Vue } from "vue-class-component";


@Options({
  components: {
  },
  props:{
    company: String
  }
})
export default class Company extends Vue {
  company!: string;
}
document.title = "UpMetric JSC";
