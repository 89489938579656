import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Main from "../views/Main.vue";
import { RouterScrollBehavior } from 'vue-router';

const scrollBehavior:RouterScrollBehavior =  (to, from, savedPosition) => {
      return new Promise<any>((resolve, reject)=>{
        resolve({top:0, left:0, behavior:'auto'})
      });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
   redirect:'/main'
  },
  {
    path: "/:view",
    name: "Main",
    component: Main
  },
  {
    path: "/case-detail",
   redirect:'/case-detail/1'
  },
  {
    path: "/case-detail/:id",
    name: "CaseDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CaseDetail.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
});

export default router;
