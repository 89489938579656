<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
 <!-- <transition @before-enter="scrollTop" mode="out-in" appear>
  <router-view />
 </transition> -->
 <!-- <router-view v-slot="{ Component }">
  <transition  @before-enter="scrollTop" mode="out-in" appear>
    <component :is="Component" />
  </transition> -->
<router-view/>
<div class="arrow-img flex flex-justify-center flex-align-center">
    <img src="~@/assets/icons/arrow-up.svg" alt=""  @click="scrollTop()"/>
</div>
</template>
<script>
import { Vue } from "vue-class-component";

export  default class App extends Vue {
  scrollTop(){
   window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
}

.arrow-img {
  position: fixed;
  background-color: $blue;
  padding: 23px;
  border-radius: 50%;
  cursor: pointer;
  bottom: 35px;
  right: 15px;
  width: 17px;
  height: 17px;
  box-shadow: 0 0 30px rgba(0,0,0,.15);
  z-index: 500;

  img{
    width: 35px;
  }
}

</style>
