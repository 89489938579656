<template>
 <div class="wrap" v-bind:style="{ textAlign: data.align}" v-bind:class="{whiteText: data.isWhite}">
     <div class="title h2-text">{{data.title}}</div>
      <div class="text">{{data.text}}</div>
 </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

 class TextTitleData{
      text!: string;
      title!: string;
      align!: string;
      isWhite!: boolean;
 }

@Options({
  props: {
    data: {type:  Object as () => TextTitleData}
  }
})
export default class TitleTextBlock extends Vue {
}
</script>

<style scoped lang="scss">
.wrap{
  padding: 20px;
  .title{
    margin-bottom: 16px;
  }
  .text{
      line-height: 1.375rem;
  }
}


@media screen and (max-width: 270px) {
    .title{
        word-break: break-all;
    }
}

</style>