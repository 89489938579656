<template>
  <div class="title top-gap-margin">
    <title-text-block
      v-bind:data="{ title, text, align: titleAlign }"
    ></title-text-block>
     </div>
    <div class="flex flex-align-baseline flex-justify-center content-wrap">
      <div class="navigation-wrap">
      <div
        class="item" @click="setActiveSpec(index)"
        v-bind:class="{ active: item.Active }"
        v-for="(item,  index) in items"
        :key="item.Title"
      >
       
          {{ item.Title }}
       
      </div>
      </div> 
      <div class="specs flex flex-wrap">
        <div v-for="item in activeSpecItems" :key="item.Icon" class="spec-item fade-in">
            <img :src="require(`@/assets/icons/${item.Icon}.svg`)" class="spec-icon" />
            <div class="spec-description">
              {{ item.Description }}
            </div> 
        </div>
      </div>
    </div>
 
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";

class SpecItem {
  Icon!: string;
  Description!: string;
}

class DataItem {
  Title!: string;
  SpecItems!: SpecItem[];
  Active?: boolean;
}

@Options({
  components: {
    TitleTextBlock,
  },
})
export default class Specialization extends Vue {
  title = "На чем мы специализируемся?";
  text = `Разработка и внедрение machine learning / deep learning driven решений.
          Реализация полного цикла разработки задач, связанных с данными: от сбора до внедрения в бизнес-процессы и сопровождения`;
  titleAlign = "center";

  items: DataItem[] = [
    {
        Active: true,
        Title: "Рекомендательные движки",
        SpecItems: [
          {
            Icon: "commerce-and-shopping",
            Description: "Персонализированные рекомендации"
          },
          {
            Icon: "message",
            Description: "Холодный старт - рекомендации без истории покупок"
          },
          {
            Icon: "profit",
            Description: "Оптимизация конверсии и маржинальности"
          }
        ],
      },
      {
        Title: "Медиа мониторинг",
        SpecItems: [
          {
            Icon: "social",
            Description: "Мониторинг масс медиа и социальных сетей"
          },
          {
            Icon: "star",
            Description: "Рейтинг освещённости организаций, лиц, мероприятий"
          },
          {
            Icon: "bar-chart",
            Description: "Сопоставление с маркетинговыми показателями"
          },
        ],
      },
      {
        Title: "Распознавание объектов",
        SpecItems: [
          { Icon: "camera", Description: "Распознавание объектов с камер" },
          {
            Icon: "security",
            Description:
              "Обеспечение безопасности, соблюдения мер предосторожности"
          },
          { Icon: "face", Description: "Распознавание лиц" }
        ],
      },
       {
        Title: "Чат-боты",
        SpecItems: [
          {
            Icon: "comment",
            Description: "Диалоговые системы для сервис центров"
          },
          {
            Icon: "automation",
            Description: "Автоматизация бизнес-процессов"
          }
        ],
      },
      {
        Title: "Задачи анализа клиентской базы",
        SpecItems: [
          { Icon: "enter", Description: "Предсказание оттока" },
          {
            Icon: "customer",
            Description:
              "Сегментация клиентов",
          },
          { Icon: "chart", Description: "Up-sell, next product to buy" },
          { Icon: "location", Description: "Гео-аналитика, оптимизация расположения точек продаж" }
        ],
      },
      {
        Title: "Монетизация данных, R&D",
        SpecItems: [
          { Icon: "science", Description: "Опыт научных исследований на международном уровне" },
          {
            Icon: "support",
            Description:
              "Консалтинг, помощь в постановке задач, связанных с данными"
          },
          { Icon: "magnifying-glass", Description: "R&D, проверка гипотез, пилоты, прототипы, A/B тестирование" }
        ],
      }
    ];

  activeSpecItems: SpecItem[] = this.items[0].SpecItems;;

  setActiveSpec(index: number) {
    this.activeSpecItems = this.items[index].SpecItems;
    this.items.forEach((i, indx) => {
      if (index === indx) {
        i.Active = true;
      } else {
        i.Active = false;
      }
    });
  }
}
</script>

<style scoped lang="scss">
.fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}



.title {
  width: 100%;
  max-width: 665px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.content-wrap{
  max-width: 965px;
  margin: 0 auto;
}

.navigation-wrap{
  border-left: 1px solid #E8E8E8;
  min-width: 270px;
}

.item:not(.active) {
  color: #a3a2bc;
  border-left: 3px solid transparent;
  transition-property: color;
  transition: 0.5s;
}
.item{
  cursor: pointer;
  padding: 15px;
  margin: 5px 5px  5px -2px;
}
.active {
  border-left: 3px solid $blue;
}

.spec-item{
  margin: 20px;
  width: 287px;

  .spec-icon{
    margin-bottom: 15px;
  }
  .spec-description{
    margin-bottom:  25px;
  }
}

@media screen and (max-width: 950px) {
   .specs{
      width: min-content;
  }
}

@media screen and (max-width: 730px) {
  .spec-item{
     margin: 10px;
     max-width: 280px;

    .spec-description{
      margin-bottom: 15px;
    }
  }
  .navigation-wrap{
    min-width: 50%;
    width: 100%;
    margin-left: 5px;

    .item{
      padding: 7px;
      margin-bottom: 10px;
    }
  }

  .specs{
      min-width: 50%;
  }
}


@media screen and (max-width: 300px) {
.navigation-wrap{
  .item{
      word-break: break-all;
    }
}

.spec-description{
      word-break: break-all;
    }
}
</style>