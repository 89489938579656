
document.title = "UpMetric JSC";

import { Options, Vue } from "vue-class-component";

export class CaseData {
  id!:number;
  headerTitle!: string;
  alias!: string;
  description!: string;
}

@Options({
  props: {
    data: { type: Object as () => CaseData },
  },
})
export default class Case extends Vue {}
