<template>
<div class="cases-wrap">
 <div class="two-column">
  <div class="right-text-block__text">
       <title-text-block
          v-bind:data="{ title, text, align: titleAlign }"
    ></title-text-block>
  </div>
  <div class="right-text-block__content">
  </div>
</div>
<div class="two-column-flex cases-wrap">
   <cases-list v-bind:items="items" v-on:case-details-clicked="details($event)"></cases-list>
</div>
 <img
        class="cases-bg"
        src="~@/assets/img/backgrounds/cases-background-lights.svg"
      />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import CaseData  from "./Case.vue";
import  CasesList from "./CasesList.vue";


@Options({
  components: {
    TitleTextBlock,
    CasesList
  },
    props: {
    items: { type: Object as () => CaseData[] },
  }
})
export default class Cases extends Vue {
   title = "Бизнес кейсы";
   text = `Члены нашей команды завершили ряд успешных проектов`;
   titleAlign = "left";

   details(event){
    window.location.href = '/case-detail/' +  event.id; // use window.location insted of router because of problems with load json data
   }
}
</script>

<style scoped lang="scss">
.cases-wrap{
  position: relative;
}
.cases-bg {
  position: absolute;
  z-index: 200;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 100%;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.two-column-flex{
  position: relative;
  z-index: 300;
}
.cases-wrap{
  margin: 0 20px;
  margin-top:20px;
}
</style>