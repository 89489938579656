
import { Options, Vue } from "vue-class-component";
import CompaniesList from "./CompaniesList.vue";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";


@Options({
  components: {
    CompaniesList,
    TitleTextBlock
  }
})
export default class Companies extends Vue {
  title = "Члены нашей команды имеют опыт работы c крупными компаниями";
  text = `Клиентами выступали как частные, так и государственные предприятия`;
  titleAlign = "left";
  companies = ['telecom', 'magnum','kazatomprom','mon', 'nb', 'almaty', 'rbk', 'beeline', 'kmf'];
}
document.title = "UpMetric JSC";
