<template>
  <div class="two-column-flex">
    <div class="order__text two-column-flex_col">
      <title-text-block
        v-bind:data="{ title, text, align: titleAlign }"
      ></title-text-block>
    </div>
    <div class="order__content flex flex-dir-col">
      <div v-if="sended" class="sended-text h4-text">{{ sendMsg }}</div>
      <div v-if="error" class="sended-text h4-text error">{{ errorMsg}}</div>
      <div
        class="order-card card flex flex-dir-col flex-align-center flex-justify-center"
      >
        <div class="form-wrap">
          <form @submit.prevent="order">
            <div class="loader" v-bind:class="{ show: loading }">
              <img class="scheme-img_mobile" src="~@/assets/img/spinner.svg" />
            </div>
            <div class="flex flex-dir-col full-width">
              <label>Имя<span class="body-lg-text">*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                v-model="name"
                required
              />
            </div>

            <div class="flex flex-dir-col full-width">
              <label>Номер телефона<span class="body-lg-text">*</span></label>
              <input
                type="tel"
                id="phone"
                name="phone"
                v-model="phone"
                v-inputmask="'+7(###)-###-##-##'"
                required
                minlength="18"
                pattern="^[+]*[7]{1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
              />
            </div>
            <div class="flex flex-dir-col full-width">
              <label
                >Краткое описание проекта<span class="body-lg-text"
                  >*</span
                ></label
              >

              <textarea id="desc" name="desc" v-model="description" required />
            </div>
            <div class="button-wrap">
              <ui-button
                v-bind:submit="true"
                v-bind:text="'Получить консультацию'"
                v-bind:type="'dark'"
                class="full-width"
              ></ui-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import UiButton from "@/components/ui/Button.vue";
import emailjs from 'emailjs-com';

@Options({
  components: {
    TitleTextBlock,
    UiButton,
  },
})
export default class Order extends Vue {
  title = "Доверьте нам свои данные";
  text = `Оставьте заявку у нас сайте, мы свяжемся с вами в ближайшее время и обсудим детали проекта`;
  titleAlign = "left";
  name!: string;
  phone!: string;
  description!: string;
  error = false;
  errorMsg = 'Произошла ошибка. Повторите попытку позже.'
  sended = false;
  sendMsg =
    "Ваша заявка отправлена! Ожидайте и с Вами свяжутся в ближайшее время.";
  loading = false;

  order(e) {
    this.error = false;
    this.sended = false;
    this.loading = true;
   

        emailjs.sendForm('service_2kxcuxi', 'template_b2mkhti', e.target, 'user_exbGXxmGE7GhTWXL9vLK9').then(()=>{
           this.sended = true;
           this.loading = false;

        }).catch((error)=>{
           console.error('error: ', {error});

          this.error = true;
          this.loading = false;
        });
        
  
  }
}
</script>

<style scoped lang="scss">
.order-card {
  padding: 40px;

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
}

.order__content {
  justify-content: center;
  display: flex;
}

.form-wrap {
  position: relative;
}

label {
  margin-bottom: 8px;
  margin-top: 14px;
}

.submit {
  margin-top: 36px;
}

.button-wrap {
  margin-top: 16px;
  text-align: center;
}

.loader {
  position: absolute;
  z-index: 400;
  position: absolute;
  z-index: 400;
  display: block;
  width: 100%;
  height: 100%;
  display: none;

  img {
    width: 70px;
  }

  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

form {
  z-index: 300;
}

.sended-text {
  padding: 10px;
  max-width: 428px;
  width: 100%;
  font-weight: bold;
  text-align: center;

  &:not(.error){
      color: $dark-blue;
  }

  &.error{
    color: $red;
  }
}

@media screen and (max-width: 450px) {
  .order__content {
    width: 100%;
  }

  .order-card {
    padding: 20px;
  }
}
</style>