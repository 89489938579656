
import { Options, Vue } from "vue-class-component";
import MainHeader from "@/components/main-page/MainHeader.vue";
import Specialization from "@/components/main-page/Specialization.vue";
import TechnologiesAndApproaches from "@/components/main-page/technologies-and-approaches/TechnologiesAndApproaches.vue";
import Team from "@/components/ui/Team.vue";
import Companies from "@/components/main-page/companies/Companies.vue";
import Cases from "@/components/main-page/cases/Cases.vue";
import Order from "@/components/main-page/Order.vue";
import Career from "@/components/main-page/Career.vue";
import UiFooter from "@/components/main-page/Footer.vue";

import  cases  from "@/assets/data/cases.js";

@Options({
  components: {
    MainHeader,
    Specialization,
    TechnologiesAndApproaches,
    Team,
    Companies,
    Cases,
    Order,
    Career,
    UiFooter,
  },
})
export default class Main extends Vue {
  team = [
    {
      name: "Кирилл Якунин",
      description: `CEO / сo-founder
              10 лет опыта в области DS/ML
              Опыт Enterprise разработки и научных исследований (PhD)`,
      alias: "kirill",
    },
    {
      name: "Санжар Мурзахметов",
      description: `CTO / co-founder
              Опыт разработки NLP сервисов и научных исследований`,
      alias: "sanzhar",
    },
    {
      name: "Абай Бегбаганбетов",
      description: `Teamlead/Software Engineer
              Backend разработчик с опытом работы в NLP, CV
              Опыт Enterprise разработки и научных исследований`,
      alias: "abay",
    },
    {
      name: "Берген Асым",
      description: `Software Engineer 
                    Backend разработчик с опытом работы в ML/DL`,
      alias: "bergen",
    },
    {
      name: "Бексултан Сагындык",
      description: `Data Scientist
                    Опыт внедрения ML решенией для бизнеса по части мобильной рекламы и геоаналитики`,
      alias: "beksultan",
    },
    {
      name: "Эльмира Баймуратова",
      description: `Senior Front-end разработчик и дизайнер с опытом UI/UX`,
      alias: "elmira",
    },
  ];

  casesList = cases;
  mounted() {
    const view = this.$router.currentRoute.value.params.view;

    if (view) {
      setTimeout(() => {
        this.goToView(view);
      }, 100);
    }
  }

   goToView(view) {
    const yOffset = -60;
    const element = document.getElementById(view);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
}
