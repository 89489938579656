
import { Options, Vue } from "vue-class-component";
import UiButton from "@/components/ui/Button.vue";

@Options({
  components: {
    UiButton,
  },
})
export default class MainHeader extends Vue {
  order() {
   this.goToView('order');
  }

  goToView(view) {
    const yOffset = -60;
    const element = document.getElementById(view);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  navigationList = [
    { title: "Специализация", alias: "specialization" },
    { title: "Технологии и подходы", alias: "technologies" },
    { title: "Команда", alias: "team" },
    { title: "Опыт", alias: "companies" },
    { title: "Бизнес кейсы", alias: "cases" },
    { title: "Консультация", alias: "order" },
    { title: "Карьера", alias: "career" },
  ];

  created() {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 1) {
        document
          .getElementById("navigation")
          ?.classList.add("navigation-white");
      } else {
        document
          .getElementById("navigation")
          ?.classList.remove("navigation-white");
      }
    });
  }
}

document.title = "UpMetric JSC";
