
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: String,
    type: String,
    disabled: Boolean,
    submit: Boolean
  },
})
export default class UiButton extends Vue {
  text!: string;
  type = "light";
  disabled = false;
  submit = false;
}
