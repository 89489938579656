<template>
  <div class="footer-wrap white-text flex flex-align-center flex-justify-space-between flex-wrap top-gap-margin"> 
    <div class="footer-block medium-bold-text">UpMetric</div>
    <div class="footer-block"> 
      <div class="footer-block_title h4-text">Контакты</div>
      <div class="footer-block_text secondary-text">+ 7 (702) 688-50-53</div>
      <div class="footer-block_text secondary-text">+ 7 (701) 728-01-82</div>
    </div>
    <div class="footer-block">
      <div class="footer-block_title h4-text">Часы работы</div>
      <div class="footer-block_text secondary-text">
        <b>Пн-Пт</b> с 9:00 до 18:00
      </div>
    </div>
    <div class="footer-block">
      <div class="footer-block_title h4-text">Email</div>
      <div class="footer-block_text secondary-text">inbox@upmetric.ai</div>
    </div>
    <div class="footer-block">
      <div class="footer-block_text secondary-text">UpMetric, {{year}}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class UiFooter extends Vue {
  year = new Date().getFullYear();
}
</script>

<style scoped lang="scss">

.footer-block_title{
  margin-bottom: 7px;
}

.footer-block{
  padding:20px;
}
</style>