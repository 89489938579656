<template>
 <div class="flex flex-justfy-start flex-align-center team-item">
   <img class="avatar" :src="require(`@/assets/img/team/${data.alias}.png`)" alt="">
   <div class="description">
     <div class="description_name h4-text">{{data.name}}</div>
     <div class="description_text">{{data.description}}</div>
   </div>
 </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

 export class TextMemberData{
      name!: string;
      alias!: string;
      description!: string;
 }

@Options({
  props: {
    data: {type:  Object as () => TextMemberData}
  }
})
export default class TeamMember extends Vue {
  
}
</script>

<style scoped lang="scss">
.description{
  margin-left: 20px;
  max-width: 375px;
  line-height: 1.375rem;
  
  .description_name{
      margin-bottom: 12px;
  }
}

.avatar{
 width: 132px; 
 border-radius: 50%;
}

.team-item{
  margin: 20px;
}

@media screen and (max-width: 450px) {
  .avatar{
      width: 95px;
    }
}

@media screen and (max-width: 300px) {
  .avatar{
      width: 85px; 
    }
}
</style>