
import { Options, Vue } from "vue-class-component";
import  TitleTextBlock  from "@/components/ui/TitleTextBlock.vue"; 


@Options({
  components: {
      TitleTextBlock
  }
})
export default class TechnologiesAndApproaches extends Vue {
  title = 'Технологии и подходы';
  text = `Мы стремимся использовать надёждные современные технологии. Благодаря опыту R&D мы можем помочь сформулировать вашу проблему и подобрать оптимальное техническое решение`;
  titleAlign = 'left';
}
