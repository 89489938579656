<template>
  <div class="companies-wrap">
    <div class="company" v-for="item in companies" :key="item">
      <company v-bind:company="item"></company>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Company from "./Company.vue";

@Options({
  components: {
    Company,
  },
  props: {
    companies:{type: Array},
  },
})
export default class CompaniesList extends Vue {}
</script>
<style scoped lang="scss">

.companies-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(168px, 168px));
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  justify-content: center;
  min-width: 513px;
}

@media screen and ( max-width: 730px) and (min-width:701px) {
.companies-wrap{
  min-width: 0;
  max-width: 515px;
  margin: 0 auto;
}
}

@media screen and (max-width: 700px) {
.companies-wrap{
  min-width: 0;
  max-width: unset;
}
}
</style>