<template>
  <div class="header flex flex-align-center flex-justify-center flex-dir-col">
    <img
      class="header-bg"
      src="~@/assets/img/backgrounds/main-header-background.svg"
    />
    <div id="navigation" class="flex flex-align-center flex-justify-start">
      <div class="logo"><img src="~@/assets/logo.svg" /></div>
      <div
        v-for="item in navigationList"
        :key="item.alias"
        @click="goToView(item.alias)"
        class="nav-item"
      >
        {{ item.title }}
      </div>
    </div>
     <div id="logo" class="logo"><img src="~@/assets/logo.svg" /></div>
    <div class="header-text flex flex-dir-col flex-align-center text-center">
      <div class="header-text_title h1-text">
        AI driven решения для вашего бизнеса
      </div>
      <div class="header-text_subtitle h3-text">
        Мы знаем что делать с вашими данными
      </div>
      <ui-button
        v-bind:text="'Получить консультацию'"
        v-on:clicked="order"
      ></ui-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import UiButton from "@/components/ui/Button.vue";

@Options({
  components: {
    UiButton,
  },
})
export default class MainHeader extends Vue {
  order() {
   this.goToView('order');
  }

  goToView(view) {
    const yOffset = -60;
    const element = document.getElementById(view);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  navigationList = [
    { title: "Специализация", alias: "specialization" },
    { title: "Технологии и подходы", alias: "technologies" },
    { title: "Команда", alias: "team" },
    { title: "Опыт", alias: "companies" },
    { title: "Бизнес кейсы", alias: "cases" },
    { title: "Консультация", alias: "order" },
    { title: "Карьера", alias: "career" },
  ];

  created() {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 1) {
        document
          .getElementById("navigation")
          ?.classList.add("navigation-white");
      } else {
        document
          .getElementById("navigation")
          ?.classList.remove("navigation-white");
      }
    });
  }
}

document.title = "UpMetric JSC";
</script>

<style scoped lang="scss">
.header-bg {
  position: absolute;
  z-index: 200;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 170%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -150px;
}
.header {
  color: $white;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.header-text {
  z-index: 300;
  position: relative;
  max-width: 670px;
  margin: 10%;

  .header-text_title {
    margin-bottom: 24px;
  }

  .header-text_subtitle {
    margin-bottom: 44px;
  }
}

#navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400;
}

.nav-item {
  margin: 20px;
  cursor: pointer;
}

.navigation-white {
  background: linear-gradient(75.87deg, #0a0475 9.95%, #0a4ade 97%);
  box-shadow: 0px 25px 35px rgba(13, 76, 216, 0.1);
}

#logo{
  display: none;
}

.logo {
  margin: 20px 30px;

  img{
    width: 130px;
  }
 
}

@media screen and (max-width: 1000px) {
   #navigation{
     display: none;
   }

   #logo{
    display: flex;
    justify-content: start;
    width: 100%;
    padding-left: 25px;
   }
}
</style>
