
import { Options, Vue } from "vue-class-component";
import TitleTextBlock from "@/components/ui/TitleTextBlock.vue";
import UiButton from "@/components/ui/Button.vue";
import emailjs from 'emailjs-com';

@Options({
  components: {
    TitleTextBlock,
    UiButton,
  },
})
export default class Order extends Vue {
  title = "Доверьте нам свои данные";
  text = `Оставьте заявку у нас сайте, мы свяжемся с вами в ближайшее время и обсудим детали проекта`;
  titleAlign = "left";
  name!: string;
  phone!: string;
  description!: string;
  error = false;
  errorMsg = 'Произошла ошибка. Повторите попытку позже.'
  sended = false;
  sendMsg =
    "Ваша заявка отправлена! Ожидайте и с Вами свяжутся в ближайшее время.";
  loading = false;

  order(e) {
    this.error = false;
    this.sended = false;
    this.loading = true;
   

        emailjs.sendForm('service_2kxcuxi', 'template_b2mkhti', e.target, 'user_exbGXxmGE7GhTWXL9vLK9').then(()=>{
           this.sended = true;
           this.loading = false;

        }).catch((error)=>{
           console.error('error: ', {error});

          this.error = true;
          this.loading = false;
        });
        
  
  }
}
